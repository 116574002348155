import React, { useEffect } from 'react';
import './VideoEditing.css';
import award from '../imgs/award.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const VideoEditing = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div className="video-editing-container">
      <div className="youtube-section">
        <h1 className="yt-title" data-aos="fade-up">Filmmaking &<br></br> Video-editing</h1>

        <iframe 
        data-aos="zoom-in" data-aos-duration="9500"
          className="youtube-iframe"
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/bTUJEyCxkWw?si=JjmR7LRO1KIEkpJV" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
      <div className="award-image-section">
        <img src={award} alt="Example" className="award-image" data-aos="flip-up" />
        <p className="image-text">"I am thrilled to share that my team, Cinematic Group Entertainment, won the special prize at Boovie, the biggest student book-trailer festival in Romania. Boovie celebrates creativity and literacy by challenging students to create compelling book-trailers. Our team's dedication and hard work paid off as we stood out among numerous talented participants." <br /> <br /> ~CGE~</p>
      </div>
    </div>
  );
}

export default VideoEditing;

