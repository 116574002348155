import React, { useEffect } from 'react';
import './Photos.css';
import vphoto from '../imgs/IMG_9332.jpg';
import ophoto from '../imgs/IMG_9343.jpg';
import gif from '../imgs/jellyfish.gif';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NotFound from './NotFound';

const Photos = () => {
    useEffect(() => {
      AOS.init();
    }, [])
  return (
    <div className="photos-container">
      <div className="vertical-image" data-aos="fade-right">
        <img src={vphoto} alt="Vertical" className="image-photos"  />
      </div>
      <div className="title-section" >
        <h1 data-aos="fade-left" data-aos-duration="9500">I don't take photos, </h1>
        <h1 className="title" >I shoot memories</h1>
        <button className="action-button" href='./NotFound.jsx'>View More - not avalilable</button>
        <div className="horizontal-image">
        
        <div className="underphoto" data-aos="fade-up"><img src={ophoto} alt="Horizontal" className="image-photos" /> <p className="image-text">Capturing moments that last a lifetime</p>
        </div>
       <img src={gif} alt="GIF" className="gif" />
      </div>
      
      </div>
    </div>
  );
}

export default Photos;
