import React, { useEffect } from 'react';
import './Contact.css';
import ContactBox from './ContactBox.jsx';
import contactimg from '../imgs/chess.gif'
import contact from '../imgs/contact.png'
import instagram from '../imgs/instagram.png'
import mail from '../imgs/mail.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="contact-container">
      <img src={contactimg} alt="Contact" className="right-image" />
      <h1 className="centered-text">Ready to make some noise?</h1>
    <div className="bottom-section" data-aos="fade-up">
    <ContactBox text={"Let's start chatting"} imageUrl={contact} hoverText={"Hello!"} />
    <ContactBox text={"Follow on Instagram"} imageUrl={instagram} hoverText={"Contact on Instagram"} link={"https://www.instagram.com/emi_tirnovan/"}/>
    <ContactBox text={"Email me"} imageUrl={mail} hoverText={"Send an email"} link={"mailto: tirnovan.emilian2004@gmail.com"} />
    </div>
  </div>
    
  );
}

export default Contact;
