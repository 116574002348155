import React, { useEffect } from 'react';
import './services.css';
import RedBox from './RedBox';
import foto from '../imgs/PHOTO.png';
import edit from '../imgs/EDIT.png';
import front from '../imgs/FRONT.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="services-container">
      <div className="services-content">
        <h1>My Services</h1>
        <div className="redboxes" data-aos="fade-up" data-aos-duration="1500">
          <RedBox imageUrl={foto} text={'"Take a shot"'} subtext={'Some detalis are cooming soon...'}/>
          <RedBox imageUrl={edit} text={'"Manipulate the reality"'} subtext={'These details are coming even sooner:)'}/>
          <RedBox imageUrl={front} text={'"The easy part of web"'} subtext={'This section is under working'}/>
        </div>
      </div>
    </div>
  );
}

export default Services;
