import React, { useEffect } from 'react';
import './App.css';
import Main from './components/main';
import Services from './components/services';
import Photos from './components/Photos';
import VideoEditing from './components/VideoEditing';
import WebDev from './components/WebDev';
import Contact from './components/Contact';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
      <Main />
      <Services />
      <Photos />
      <VideoEditing />
      <WebDev />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
