import React from 'react';
import './ContactBox.css';

const ContactBox = ({ text, imageUrl, hoverText, link }) => {
  return (
    <div className="contact-box">
      <div className="content-contact">
        <div className="text">{text}</div>
        <div className="image-container-contact">
          <div className="hover-text-contact"><a href={`${link}`}>{hoverText}</a><img src={imageUrl} alt="Contact" className="image-contact" /></div>
          
        </div>
      </div>
    </div>
  );
}

export default ContactBox;
