import React from 'react';
import './RedBox.css';

const RedBox = ({ imageUrl, text, subtext }) => {
  return (
    <div className="red-box">
      <div className="image-container">
        <img src={imageUrl} alt="RedBox" className="image" />
      </div>
      <div className="text-container">
        <div className="text">{text}</div>
        <div className="subtext">{subtext}</div>
      </div>
    </div>
  );
}

export default RedBox;
