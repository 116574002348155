import React, { useEffect } from 'react';
import './WebDev.css';
import comingsoon from '../imgs/coming-soon.gif'
import AOS from 'aos';
import 'aos/dist/aos.css';
const WebDev = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const text = "This section is under construction";

  return (
    <div className="web-dev-container" >
        <h1 className='title-web'>Web-Dev</h1>
      <img src={comingsoon} data-aos="fade-up" alt="Under Construction" className="center-image" />
      <h1 className="animated-text" data-aos="fade-in">
        {text.split('').map((char, index) => (
          <span key={index}>{char}</span>
        ))}
      </h1>
    </div>
  );
}

export default WebDev;
