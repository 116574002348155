import React, { useEffect } from 'react';
import './main.css';
import video from '../imgs/bg-waves.mp4';
import logo from '../imgs/logo-black.png';
import down from '../imgs/down.gif';
import AOS from 'aos';
import 'aos/dist/aos.css';
 const scrollToBottom = () => {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      });
    };
const Main = () => {
  useEffect(() => {
      AOS.init();
    }, [])
  return (
    
    <div className="video-container">
      <video autoPlay loop muted className="video-bg">
        <source src={video}  type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <div className="logo" data-aos="zoom-out" data-aos-duration="1000"><h1>WELCOME TO</h1>
        <img src={logo} alt="main-logo" /></div>
        
        <button className="action-button" onClick={scrollToBottom}>FIND MORE</button>
        <img className='down' src={down} alt="scroll down" />
      </div>
    </div>
  );
}

export default Main;
